<template>
    <div class="system-setting">
        <edit :fs="useChatFields" api="settings" id="use-chat" title="Use Chat"></edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import { useChatFields } from "@/config";
export default {
    components: {
        Edit
    },
    data() {
        return {};
    },
    computed: {
        useChatFields() {
            return useChatFields;
        }
    },
    methods: {},
    created() {}
};
</script>
<style lang="less">
.system-setting {
    max-width: 1000px;
    margin: auto;
}
</style>
